import * as React from "react"
import { Trans } from "react-i18next"

const Input = ({
  id,
  name,
  label,
  placeholder,
  inputClassName = "",
  labelClassName = "",
  wrapperClassName = "",
  formik,
  onChange,
  onBlur,
  value,
  type,
}) => {
  if (type === "hidden") {
    return <input type="hidden" value={value} name={name} id={id}></input>
  }
  return (
    <>
      <div className={`relative z-0 mb-5 w-full ${wrapperClassName}`}>
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className={`text-gray-900 peer rounded-xl block w-full appearance-none border border-gray-dark bg-transparent px-4 pb-2.5 pt-4 text-sm focus:border-gray-dark focus:outline-none focus:ring-0 ${
            formik && formik.touched[name] && formik.errors[name] ? "border-red-700" : ""
          } ${inputClassName}`}
        />
        {label && (
          <label
            htmlFor={name}
            className={`text-gray-500 -z-10 absolute top-0 left-3 origin-[0] -translate-y-4 scale-75 transform bg-gray px-2 text-lg duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-90 peer-focus:top-0 peer-focus:z-10 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-dark ${labelClassName}`}
          >
            <Trans>{label}</Trans>
          </label>
        )}
        {formik && formik.touched[name] && formik.errors[name] ? (
          <div className="absolute top-full right-0 text-xs text-red-700">
            <Trans>{formik.errors[name]}</Trans>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Input
