import * as React from "react"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../../atoms/button"
import { HOMEPAGE_SLUG } from "../../../constants/constants"

const HeroCoverImage = ({ content }) => {
  const coverImage = getImage(content.image)
  const ctaButton = content.ctaButton[0]
  const url = ctaButton
    ? ctaButton.external
      ? ctaButton.destinationExternal
      : `${ctaButton.destination.slug === HOMEPAGE_SLUG ? "/" : `/${ctaButton.destination.slug}`}`
    : ""

  return (
    <section
      className={`${content.__typename} mx-auto my-10 overflow-y-clip md:container md:relative md:my-20 md:flex md:min-h-[500px]`}
    >
      <div className="px-6 py-8 text-center md:w-1/2 md:pb-0 md:pl-14 md:pr-0 md:text-left lg:w-2/5">
        <h2 className="pb-8 font-philosopher text-4xl lg:pb-14 lg:pt-16">{content.headline || ""}</h2>
        {parse(content.paragraph)}
        {ctaButton && (
          <Button
            className="mt-5 md:mt-10"
            url={url}
            text={ctaButton.labelText}
            color="primary"
            external={ctaButton.external}
          />
        )}
      </div>
      {coverImage ? (
        <GatsbyImage
          className="absolute-md-required max-w-[840px] md:-right-[26.25rem] md:max-h-[500px] md:w-full lg:-right-72 xl:-right-28"
          imgStyle={{ objectFit: "fill" }}
          image={coverImage}
          alt={content.image.alt || ""}
        />
      ) : (
        ""
      )}
    </section>
  )
}

export default HeroCoverImage
