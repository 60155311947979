import React, { useState } from "react"
import { useFormik } from "formik"
import { Trans } from "react-i18next"
import IconSend from "../../icons/send.svg"
import Input from "../atoms/input"
import TextArea from "../atoms/textarea"
import Spinner from "../atoms/spinner"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "Required"
  } else if (values.name.length <= 1) {
    errors.name = "Must be at least 2 characters"
  }
  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }
  if (!values.subject) {
    errors.subject = "Required"
  } else if (values.subject.length <= 1) {
    errors.subject = "Must be at least 2 characters"
  }
  if (!values.message) {
    errors.message = "Required"
  } else if (values.message.length <= 1) {
    errors.message = "Must be at least 2 characters"
  }
  return errors
}

const ContactForm = ({ hasInformations }) => {
  const [busy, setBusy] = useState(false)
  const [showThankyou, setShowThankyou] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate,
    onSubmit: values => {
      setBusy(true)
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...values,
        }),
      })
        .then(res => {
          if (res.ok) {
            setShowThankyou(true)
          }
          setBusy(false)
        })
        .catch(error => {
          setBusy(false)
          console.error(error)
        })
    },
  })

  return (
    <div className="relative">
      <div
        className={`${
          busy ? "flex" : "hidden"
        } absolute z-50 h-full w-full items-center justify-center bg-gray bg-opacity-80`}
      >
        <Spinner></Spinner>
      </div>
      <div className={`${showThankyou ? "block" : "hidden"}`}>
        <h3 className="text-lg font-bold">
          <Trans>Thank You!</Trans>
        </h3>
        <p>
          <Trans>Your submission has been sent.</Trans>
        </p>
      </div>
      <form
        className={`${showThankyou ? "hidden" : "block"}`}
        name="contact"
        onSubmit={formik.handleSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        noValidate
      >
        <Input type="hidden" name="form-name" value="contact" />
        <Input type="hidden" name="bot-field" />

        <Input
          name="name"
          type="text"
          placeholder=" "
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          wrapperClassName={`${hasInformations && "lg:w-6/12"}`}
          label="Your name"
        />

        <Input
          name="email"
          type="email"
          placeholder=" "
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          wrapperClassName={`${hasInformations && "lg:w-6/12"}`}
          label="Your email"
        />

        <Input
          name="phone"
          type="tel"
          placeholder=" "
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          wrapperClassName={`${hasInformations && "lg:w-3/12"}`}
          label="Your phone"
        />

        <Input
          name="subject"
          type="text"
          placeholder=" "
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.subject}
          wrapperClassName={`${hasInformations && "lg:w-7/12"}`}
          label="Your subject"
        />

        <TextArea
          name="message"
          placeholder=" "
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          wrapperClassName={`${hasInformations && "lg:w-9/12"}`}
          textareaClassName="h-56"
          label="Your message"
        />

        <button
          className="inline-block rounded-xl w-auto bg-primary p-3 px-14 text-sm text-white transition-opacity duration-300 ease-in-out hover:opacity-80  md:text-base lg:text-xl"
          type="submit"
        >
          <Trans>Send</Trans>
          <IconSend className="ml-4 -mt-0.5 inline-block"></IconSend>
        </button>
      </form>
    </div>
  )
}

export default ContactForm
