import * as React from "react"
import parse from "html-react-parser"
import ContactForm from "../../molecules/contactForm"

const Contact = ({ content }) => {
  const hasInformations = content.informations.length > 0
  return (
    <section className={`${content.__typename} container mx-auto px-3 sm:px-0 mb-5`}>
      <h2 className="pb-8 pt-10 text-center font-philosopher text-4xl lg:pb-12 lg:pt-16">{content.headline || ""}</h2>
      <div className="container mx-auto grid grid-cols-12 gap-4">
        {hasInformations && (
          <div className="col-span-12 md:col-span-4">
            <ul>
              {content.informations.map(element => (
                <li key={element.id}>
                  <h3 className="pb-4 font-philosopher text-xl font-bold">{element.headline}</h3>
                  <div className="pb-6">{parse(element.informations)}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={`${hasInformations ? "col-span-12 md:col-span-7 md:col-start-6 lg:col-span-8" : "col-span-12 lg:col-start-4 lg:col-span-6 text-center"}`}>
          <p className="font-philosopher text-lg">{content.paragraph || ""}</p>
          <div className="pt-14">
            <ContactForm hasInformations={hasInformations}></ContactForm>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
