import React, { useState, useEffect } from "react"
import { Trans, useI18next, Link } from "gatsby-plugin-react-i18next"
import { DebounceInput } from "react-debounce-input"
import { usePostsQuery, useCategoriesQuery } from "../../../queries"
import { BLOG_SLUG } from "../../../constants/constants"
import IconClose from "../../../icons/close.svg"
import "../../../styles/article.scss"

const ArticlesListWithSearch = ({ content }) => {
  const { language } = useI18next()
  const limit = content.articlesPerPage || 9
  const posts = usePostsQuery()
  const categories = useCategoriesQuery()
  const allCategories = categories.filter(category => category.locale === language)
  const allArticles = posts.filter(post => post.locale === language)
  const [articles, setArticles] = useState(allArticles)
  const [articlesVisible, setList] = useState([...articles.slice(0, limit)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(articles.length > limit)
  const [inputQuery, setInputQuery] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = articlesVisible.length
      const isMore = currentLength < articles.length
      const nextResults = isMore ? articles.slice(currentLength, currentLength + limit) : []
      setList([...articlesVisible, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  //Check if there is more
  useEffect(() => {
    const isMore = articlesVisible.length < articles.length
    setHasMore(isMore)
  }, [articlesVisible])

  // Update filtred data
  useEffect(() => {
    setList([...articles.slice(0, limit)])
  }, [articles])

  const handleLoadMore = () => setLoadMore(true)

  const handleInputChange = event => {
    const query = event.target.value
    setInputQuery(query)
  }

  useEffect(() => {
    const filteredArticles = allArticles
      .filter(post => {
        const title = post.title
        const author = post.author?.name
        return (
          title.toLowerCase().includes(inputQuery.toLowerCase()) ||
          author?.toLowerCase().includes(inputQuery.toLowerCase())
        )
      })
      .filter(post =>
        post.category.find(item => {
          if (!selectedCategory) {
            return true
          }
          return item.originalId === selectedCategory
        })
      )
    setArticles(filteredArticles)
  }, [inputQuery, selectedCategory])

  const handleCategoryChange = (categoryId, isSelectedCategory) => {
    if (!isSelectedCategory) {
      setSelectedCategory(categoryId)
      return
    }
    setSelectedCategory("")
  }

  const handleClearInput = () => {
    setArticles(allArticles)
    setInputQuery("")
    setSelectedCategory("")
  }

  return (
    <section className={`${content.__typename} px-5`}>
      <div className="container mx-auto px-3 sm:px-0">
        <div className="relative mt-16 flex items-center md:w-min">
          <DebounceInput
            type="text"
            aria-label="Search"
            placeholder=" "
            className="text-gray-900 border-1 border-gray-300 dark:border-gray-600 peer block w-full appearance-none border border-gray-dark bg-white px-5 py-2.5 text-sm focus:border-gray-dark focus:outline-none focus:ring-0 dark:text-white dark:focus:border-gray-dark md:w-[400px]"
            id="search_input"
            value={inputQuery}
            minLength={2}
            debounceTimeout={500}
            onChange={handleInputChange}
          />
          <label
            htmlFor="search_input"
            className="text-gray-500 dark:text-gray-400 dark:bg-gray-900 absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-dark peer-focus:dark:text-gray-dark"
          >
            <Trans>Search for an article</Trans>
          </label>
          {inputQuery.length ? (
            <IconClose className="absolute right-4 cursor-pointer" onClick={handleClearInput} />
          ) : (
            ""
          )}
        </div>

        <ul className="mt-7 mb-11 flex md:ml-0">
          {allCategories.map((category, index) => {
            const isLastCategory = index === allCategories.length - 1
            const isSelectedCategory = category.originalId === selectedCategory
            return (
              <li key={category.id} className="flex w-min items-center">
                <span
                  className={`${isSelectedCategory ? "text-primary" : ""} cursor-pointer select-none`}
                  onClick={() => {
                    handleCategoryChange(category.originalId, isSelectedCategory)
                  }}
                >
                  {category.name}
                </span>
                {!isLastCategory ? <span className="px-3">|</span> : ""}
              </li>
            )
          })}
        </ul>

        <ul className="flex grid-cols-3 flex-col gap-x-5 gap-y-6 space-y-5 md:grid md:space-y-0">
          {articlesVisible.map(article => {
            return (
              <li key={article.id} className="article">
                <Link to={`/${BLOG_SLUG}/${article.slug}`}>
                  <div className="relative flex h-[510px] flex-col">
                    {article.category && (
                      <div className="absolute -top-2.5 left-6 flex space-x-3">
                        {article.category.map((element, index) => (
                          <span key={index} className="bg-primary px-5 py-2 text-xs text-white lg:py-2">
                            {element.name}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className="flex h-full justify-center">
                      <img className="h-full w-full object-cover" src={article.coverImage.url} />
                    </div>
                    <div className="article-body absolute bottom-0 h-[35%] w-full overflow-hidden bg-gray p-6 transition-[height] duration-300 md:h-[30%]">
                      <h2 className="mb-5 text-xl">{article.title}</h2>
                      <p className="article-excerpt line-clamp-2">{article.excerpt}</p>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
        {hasMore ? (
          <button
            className="mx-auto mt-12 flex cursor-pointer items-center rounded-xl bg-primary px-12 py-4 text-white"
            onClick={handleLoadMore}
          >
            <Trans>Load more</Trans>
          </button>
        ) : (
          <p className="mx-auto mt-3 w-max md:mt-6">
            <Trans>No more results</Trans>
          </p>
        )}
      </div>
    </section>
  )
}

export default ArticlesListWithSearch
