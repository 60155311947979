import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, EffectFade, Scrollbar, Autoplay } from "swiper"
import "swiper/css/bundle"
import "../../../styles/swiper.scss"
import { useSocialQuery } from "../../../queries"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { FacebookBlackIcon, InstagramBlackIcon, LinkedinBlackIcon, TiktokBlackIcon, TwitterBlackIcon, YoutubeBlackIcon } from "../../../icons/social-media"
import IconArrowDown from "../../../icons/arrow-down.svg"

const HeroWelcome = ({ content }) => {
  const { language } = useI18next()
  const socialMediaResult = useSocialQuery()
  const socialMediaResultTranslated = socialMediaResult.filter(item => item.locale === language)
  const slides = content.slides
  const delay = content.sliderDelay
  const isHeaderAffected = content.affectsHeader
  return (
    <section className={`${content.__typename}`}>
      <Swiper
        className="flex h-screen w-full"
        modules={[Scrollbar, Autoplay, EffectFade, Pagination]}
        effect={"fade"}
        allowTouchMove={false}
        breakpoints={{
          976: {
            allowTouchMove: true,
          },
        }}
        speed={1000}
        pagination={{
          type: "custom",
          verticalClass: "pagination-container",
          el: ".pagination-container",
          renderCustom: function (_, __, totalVal) {
            return `
            <span>01</span>
            <span>${totalVal < 10 ? "0" + totalVal : totalVal}</span>
            `
          },
        }}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={delay ? { delay } : false}
        direction="vertical"
        scrollbar={{
          el: ".swiper-scrollbar",
          dragClass: "swiper-scrollbar-drag",
          draggable: true,
        }}
        slidesPerView={1}
        slidesPerGroupSkip={1}
      >
        {slides.map(singleSlide => {
          return (
            <SwiperSlide key={singleSlide.originalId} className="relative flex justify-center">
              <div
                className="h-screen w-full"
                style={{
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundImage: `url(${singleSlide.image.url})`,
                }}
              >
                <div className="container mx-auto flex h-screen items-center justify-center">
                  <h3 className="max-w-[50%] text-center font-philosopher text-4xl font-bold md:text-5xl md:font-normal md:leading-tight lg:text-85 ">
                    {singleSlide.headline || ""}
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
        <div className="container pointer-events-none absolute inset-x-0 z-20 mx-auto hidden h-full w-full items-center justify-end px-2 lg:flex lg:px-6">
          <div className="pagination-container pointer-events-auto flex  h-[260px] flex-col items-center justify-between" />
        </div>
        <div className="container pointer-events-none absolute inset-x-0 z-20 mx-auto hidden h-full w-full cursor-pointer items-center justify-end px-2 lg:flex lg:px-6">
          <div className="swiper-scrollbar swiper-scrollbar-vertical pointer-events-auto">
            <div className="swiper-scrollbar-drag"></div>
          </div>
        </div>
        {isHeaderAffected && (
          <div className="container pointer-events-none absolute inset-x-0 bottom-0 z-20 mx-auto flex h-full w-full flex-col justify-center pl-4 md:justify-end lg:px-6">
            <ul className="hero-social-media pointer-events-auto flex w-4 flex-col items-center space-y-4">
              {socialMediaResultTranslated.map(singleMedium => {
                const outputSocialMediaIcon = () => {
                  switch (singleMedium.platform) {
                    case "facebook":
                      return <FacebookBlackIcon className="w-8 h-8" />
                    case "youtube":
                      return <YoutubeBlackIcon className="w-8 h-8" />
                    case "instagram":
                      return <InstagramBlackIcon className="w-8 h-8" />
                    case "linkedin":
                      return <LinkedinBlackIcon className="w-8 h-8" />
                    case "tiktok":
                      return <TiktokBlackIcon className="w-8 h-8" />
                    case "twitter":
                      return <TwitterBlackIcon className="w-8 h-8" />
                    default:
                      return null
                  }
                }

                return (
                  <li key={singleMedium.originalId}>
                    <a
                    className="hover:opacity-50 transition-opacity"
                      target="_blank"
                      rel="noreferrer"
                      aria-current="page"
                      title={singleMedium.headline || ""}
                      href={singleMedium.url || "#"}
                    >
                      {outputSocialMediaIcon()}
                    </a>
                  </li>
                )
              })}
            </ul>

            <div className="mt-5 flex animate-bounce flex-col items-start space-y-5 space-x-1 pb-4 md:pb-10">
              <span className="-scale-100 select-none" style={{ writingMode: "vertical-lr" }}>
                <Trans>See more</Trans>
              </span>
              <IconArrowDown className="h-[50px] w-4" />
            </div>
          </div>
        )}
      </Swiper>
    </section>
  )
}

export default HeroWelcome
