import * as React from "react"
import { Link } from "gatsby-plugin-react-i18next"

const Button = ({ text, color, className, url = "", onClick, external = false, children }) => {

  const colors = {
    primary: "bg-primary text-white",
    white: "bg-white text-primary",
  }
  return external ? (
    <a
      role="button"
      target="_blank"
      rel="noreferrer"
      className={`inline-block rounded-xl p-3 text-sm transition-opacity duration-300 ease-in-out hover:opacity-80 md:p-5 md:text-base lg:text-xl ${colors[color]} ${className}`}
      onClick={onClick}
      href={url}
    >
      {text}
      {children}
    </a>
  ) : (
    <Link
      role="button"
      className={`inline-block rounded-xl p-3 text-sm transition-opacity duration-300 ease-in-out hover:opacity-80 md:p-5 md:text-base lg:text-xl ${colors[color]} ${className}`}
      activeClassName="text-primary"
      to={url}
      onClick={onClick}
    >
      {text}
      {children}
    </Link>
  )
}

export default Button
