import * as React from "react"
import parse from "html-react-parser"
import "../../../styles/counter.scss"

const NumericGrid = ({ content }) => {
  return (
    <section className={`${content.__typename} counter-numeric-grid container mx-auto my-10 md:my-20 px-5 md:px-0`}>
      <h2 className="pb-8 text-center font-philosopher text-4xl md:text-left lg:pb-10">{content.heading}</h2>
      <ul className="flex flex-col flex-wrap items-center sm:flex-row sm:items-start sm:justify-around md:justify-start md:gap-x-24 lg:gap-x-0 xl:gap-x-24">
        {content.paragraphs.map(singleParagraph => {
          return (
            <li
              className="my-4 flex w-4/5 before:relative before:bottom-7 before:font-philosopher before:text-8xl before:text-primary before:first-of-type:ml-[1.375rem] md:w-2/5 lg:w-1/3 xl:w-[345px]"
              key={singleParagraph.id}
            >
              {parse(singleParagraph.paragraph)}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default NumericGrid
