/* eslint-disable no-unused-vars */
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { BLOG_SLUG } from "../../../constants/constants"
import ArrowRightWhite from "../../../icons/arrow-right-white.svg"

const ArticlesLatest = ({ content }) => {
  const articles = content.articles
  const articlesLimit = 5
  const articlesRestricted = articles.filter((_, index) => index < articlesLimit)
  return (
    <section className={`${content.__typename} container mx-auto my-10 md:my-20 px-5`}>
      <h2 className="pb-8 text-center font-philosopher text-4xl lg:pb-10 lg:text-left">{content.headline || ""}</h2>
      {content.paragraph && (
        <p className="mx-auto mb-10 px-6 text-center md:w-2/5 md:px-0 lg:mx-0 lg:text-left lg:line-clamp-4">
          {content.paragraph}
        </p>
      )}
      <ul className="flex grid-cols-3 flex-col items-center gap-x-5 gap-y-0 md:gap-y-6 space-y-5 md:grid md:space-y-0">
        {articlesRestricted.map(article => {
          const image = getImage(article.coverImage)
          return (
            <li key={article.originalId} className="article">
              <Link to={`/${BLOG_SLUG}/${article.slug}`}>
                <div className="relative flex h-[510px] flex-col">
                  {article.category && (
                    <div className="absolute top-6 left-4 z-10 flex space-x-3">
                      {article.category.map((element, index) => (
                        <span key={index} className="rounded-xl bg-primary px-3 py-0.5 text-xs text-white">
                          {element.name}
                        </span>
                      ))}
                    </div>
                  )}
                  <div className="flex h-full justify-center">
                    <GatsbyImage className="h-full w-full object-cover" image={image} alt={image.alt || ""} />
                  </div>
                  <div className="article-body absolute bottom-0 h-[35%] w-full overflow-hidden bg-[#d7d7d7] px-5 py-6 transition-[height] duration-300 md:h-[30%]">
                    <h2 className="mb-5 text-xl">{article.title}</h2>
                    <p className="article-excerpt line-clamp-2">{article.excerpt}</p>
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
        <li className="w-full">
          <Link to={`/${BLOG_SLUG}`}>
            <div className="flex md:h-[510px] items-center justify-center bg-primary text-white rounded-xl md:rounded-none">
              <h2 className="py-2 md:py-0 mb-0 mr-8 font-philosopher text-4xl md:mr-6 md:text-3xl lg:mr-8 lg:text-4xl">
                <Trans>Read more</Trans>
              </h2>
              <ArrowRightWhite className="w-[50px]" />
            </div>
          </Link>
        </li>
      </ul>
    </section>
  )
}

export default ArticlesLatest
