import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, EffectFade, EffectCube, EffectFlip, EffectCoverflow } from "swiper"
import "swiper/css/bundle"
import "../../../styles/swiper.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import IconArrowLeft from "../../../icons/arrow-left.svg"
import IconArrowRight from "../../../icons/arrow-right.svg"
import { Link } from "gatsby-plugin-react-i18next"
import { BLOG_SLUG } from "../../../constants/constants"

const CarouselWithParagraph = ({ content }) => {
  const overlapping = content.sectionOverlappingEffect
  const effect = content.transitionType

  const articles = content.articles
  return (
    <section className={`${content.__typename} ${overlapping ? "md:-mb-24" : ""} container mx-auto my-10 md:my-20`}>
      <div className={`${overlapping ? "md:bottom-36" : ""} md:relative`}>
        <Swiper
          modules={[Navigation, Pagination, EffectFade, EffectCube, EffectFlip, EffectCoverflow]}
          effect={effect}
          // fade
          fadeEffect={{
            crossFade: true,
          }}
          // coverflow
          coverflowEffect={{
            rotate: 30,
            slideShadows: false,
            depth: 200,
            stretch: -500,
          }}
          // flip
          flipEffect={{
            slideShadows: false,
          }}
          // cube
          cubeEffect={{
            slideShadows: false,
          }}
          navigation={{
            nextEl: ".navigation-button-next",
            prevEl: ".navigation-button-prev",
            disabledClass: "navigation-button-disabled",
          }}
          pagination={{
            type: "fraction",
            el: ".pagination-fraction",
          }}
          breakpoints={{
            320: {
              allowTouchMove: true,
            },
            768: {
              allowTouchMove: false,
            },
          }}
          className="w-full"
        >
          {articles.map(singleArticle => {
            const image = getImage(singleArticle.coverImage)
            return (
              <SwiperSlide key={singleArticle.originalId} className="flex flex-col md:flex-row md:justify-start">
                <Link to={`/${BLOG_SLUG}/${singleArticle.slug}`} className="group md:w-1/2 xl:w-[715px]">
                  <GatsbyImage
                    className="h-[430px] w-full transition-opacity group-hover:opacity-90 md:rounded-xl"
                    image={image}
                    alt={image.alt || ""}
                  />
                </Link>
                <div className="mx-4 md:mx-0 md:mt-32 md:max-w-[50%] md:pl-5 xl:max-w-[485px]">
                  <h3 className="my-3 font-semibold md:mb-6 md:mt-0 md:truncate">
                    <Link to={`/${BLOG_SLUG}/${singleArticle.slug}`}>{singleArticle.title || ""}</Link>
                  </h3>
                  <p className="line-clamp-4 md:line-clamp-[8]">{singleArticle.excerpt || ""}</p>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="mx-4 mt-5 flex justify-between md:mx-0 md:w-1/2 xl:w-[715px]">
          <IconArrowLeft className="navigation-button-prev w-[50px] cursor-pointer" />
          <div className="pagination-fraction flex justify-center" />
          <IconArrowRight className="navigation-button-next w-[50px] cursor-pointer" />
        </div>
      </div>
    </section>
  )
}

export default CarouselWithParagraph
