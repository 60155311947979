import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/bundle"
import "../../../styles/swiper.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-i18next"
import { BLOG_SLUG } from "../../../constants/constants"
import IconArrowRightWhite from "../../../icons/arrow-right-white.svg"

const Carousel = ({ content }) => {
  const articles = content.articles
  return (
    <section className={`${content.__typename} container my-10 mx-auto flex md:relative md:my-20 xl:h-[500px]`}>
      <Swiper
        className="w-full md:pr-25"
        slidesPerView={1.1}
        spaceBetween={10}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          976: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {articles.map(singleArticle => {
          const image = getImage(singleArticle.coverImage)
          return (
            <SwiperSlide key={singleArticle.originalId} className="relative md:max-w-[400px]">
              <Link to={`/${BLOG_SLUG}/${singleArticle.slug}`} className="group">
                <GatsbyImage
                  className="h-[500px] transition-opacity group-hover:opacity-90 md:max-w-[400px] md:rounded-xl"
                  image={image}
                  alt={image.alt || ""}
                />
                <div className="gradient-carousel absolute bottom-0 flex h-full w-full items-end justify-between px-10 pb-5 text-white md:max-w-[400px] md:rounded-b-xl">
                  <h3 className="mr-5 truncate transition-all group-hover:pl-3">{singleArticle.title || ""}</h3>
                  <IconArrowRightWhite className="mb-1 w-[50px] transition-all group-hover:-mr-5" />
                </div>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Carousel
