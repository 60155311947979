import * as React from "react"

const PageHeadline = ({ content, page }) => {
  return (
    <section className="container my-10 mx-auto px-3 md:my-20">
        <h2 className={`text-${content.titleAlignment} font-philosopher text-4xl`}>{page.title || ""}</h2>
    </section>
  )
}

export default PageHeadline