import * as React from "react"
import { StructuredText } from "react-datocms"
import { BLOG_SLUG } from "../../../constants/constants"
import ImagesGallery from "./imagesGallery"

const FormattedText = ({ content }) => {
  return (
    <section className="container my-10 mx-auto px-3 md:my-20">
        <div className="text-structured">
          <StructuredText
            data={content.content}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsImagesGallery":
                  return (<ImagesGallery content={record} />)
                default:
                  return null
              }
            }}
            renderInlineRecord={({ record, children, transformedMeta }) => {
              switch (record.__typename) {
                case "DatoCmsPage":
                  return (
                    <a {...transformedMeta} href={`/${record.slug}`}>
                      {children}
                    </a>
                  )
                case "DatoCmsPost":
                  return (
                    <a {...transformedMeta} href={`/${BLOG_SLUG}/${record.slug}`}>
                      {children}
                    </a>
                  )
                default:
                  return null
              }
            }}
            renderLinkToRecord={({ record, children, transformedMeta }) => {
              switch (record.__typename) {
                case "DatoCmsPage":
                  return (
                    <a {...transformedMeta} href={`/${record.slug}`}>
                      {children}
                    </a>
                  )
                case "DatoCmsPost":
                  return (
                    <a {...transformedMeta} href={`/${BLOG_SLUG}/${record.slug}`}>
                      {children}
                    </a>
                  )
                default:
                  return null
              }
            }}
          />
        </div>
    </section>
  )
}

export default FormattedText
