import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-i18next"
import { BLOG_SLUG } from "../../../constants/constants"

const ArticlesChessboard = ({ content }) => {
  const allArticles = content.articles
  return (
    <section className={`${content.__typename} container mx-auto mt-10 md:mt-20`}>
      <h2 className="pb-8 text-center font-philosopher text-4xl md:text-left lg:pb-10">{content.heading || ""}</h2>
      <ul className="md:flex md:flex-wrap md:gap-x-5">
        {allArticles.map((singleArticle, index) => {
          const image = getImage(singleArticle.coverImage)
          const itemsPerRow = 2
          const isArticleInOddRow = Math.floor(index / itemsPerRow) % 2 === 0

          return (
            <li
              key={singleArticle.id}
            >
              <Link to={`/${BLOG_SLUG}/${singleArticle.slug}`} className="group mx-auto mb-16 flex w-4/5 flex-col md:mx-0 md:mb-24 md:max-w-[374px] md:flex-row lg:max-w-[478px] xl:max-w-[590px]">
              <div
                className={`${
                  isArticleInOddRow ? "md:pr-3 md:text-right" : "md:pl-3"
                } mb-3 flex flex-col text-xl md:order-1 md:mt-10 md:mb-5 md:w-1/2 lg:max-w-[295px]`}
              >
                <h3 className="mb-3 font-semibold md:mb-3 md:truncate">{singleArticle.title || ""}</h3>
                <p className="md:line-clamp-4">{singleArticle.excerpt || ""}</p>
              </div>
              <GatsbyImage
                className={`${
                  isArticleInOddRow ? "order-2" : ""
                } h-[213px] rounded-xl md:w-1/2 lg:w-1/2 lg:max-w-[295px] group-hover:opacity-90 transition-opacity`}
                image={image}
                alt={image.alt || ""}
              />
              </Link>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default ArticlesChessboard
