import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImagesGrid = ({ content }) => {
  const images = content.images || []
  const chunkSize = 4

  const chunk = (initialArray, chunkSize) =>
    initialArray.reduce(
      (accumulator, currentElement, index) => (
        index % chunkSize
          ? accumulator[accumulator.length - 1].push(currentElement)
          : accumulator.push([currentElement]),
        accumulator
      ),
      []
    )

  const logosChunked = chunk(images, chunkSize)

  return (
    <section className={`${content.__typename} container mx-auto my-10 md:my-20`}>
      <h2 className="mb-8 text-center font-philosopher text-4xl">{content.headline || ""}</h2>
      {logosChunked.map((singleChunk, index) => {
        const isChunkFilledOverHalf = singleChunk.length >= chunkSize / 2
        return (
          <div key={index}>
            <ul
              className={`${
                isChunkFilledOverHalf ? "md:justify-between" : ""
              } lg:gap-x-18 flex flex-wrap justify-center gap-x-4 sm:gap-x-20 md:gap-x-8 lg:gap-x-12 xl:gap-x-20`}
            >
              {singleChunk.map((singleImage, index) => {
                const image = getImage(singleImage)
                return (
                  <li
                    key={index}
                    className={`${
                      isChunkFilledOverHalf ? "xl:w-max" : ""
                    } my-2 flex w-2/5 items-center justify-center overflow-hidden md:my-7 md:w-1/5 xl:my-0`}
                  >
                    <GatsbyImage imgClassName="grayscale hover:grayscale-0" image={image} alt={singleImage.alt || ""} />
                  </li>
                )
              })}
            </ul>
            {index < logosChunked.length - 1 ? <div className="my-7 hidden h-0.5 bg-gray xl:block" /> : ""}
          </div>
        )
      })}
    </section>
  )
}

export default ImagesGrid
