import * as React from "react"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../../atoms/button"
import { HOMEPAGE_SLUG } from "../../../constants/constants"

const HeroBackgroundImage = ({ content }) => {
  const backgroundImage = getImage(content.image)
  const ctaButton = content.ctaButton[0]
  const url = ctaButton
    ? ctaButton.external
      ? ctaButton.destinationExternal
      : `${ctaButton.destination.slug === HOMEPAGE_SLUG ? "/" : `/${ctaButton.destination.slug}`}`
    : ""

  return (
    <section className={`${content.__typename} mx-auto flex-col items-center text-xl md:relative md:flex md:h-[920px]`}>
      <div className="p-8 text-center md:inset-x-0 md:z-10 md:mx-auto md:max-w-lg lg:mt-48">
        <h2 className="pb-8 font-philosopher text-4xl lg:pb-10">{content.headline || ""}</h2>
        {parse(content.paragraph)}
        {ctaButton && (
          <Button
            className="mt-5 text-base md:mt-10"
            url={url}
            text={ctaButton.labelText}
            color="primary"
            external={ctaButton.external}
          />
        )}
      </div>
      {backgroundImage ? (
        <GatsbyImage
          className="absolute-md-required md:h-full"
          src={content.image.url}
          formats={["auto", "webp", "avif"]}
          layout="fullWidth"
          image={backgroundImage}
          alt={content.image.alt || ""}
        />
      ) : (
        ""
      )}
    </section>
  )
}

export default HeroBackgroundImage
