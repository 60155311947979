import * as React from "react"
import HeroCoverImage from "./heroCoverImage"
import HeroBackgroundImage from "./heroBackgroundImage"
import HeroSolidBackground from "./heroSolidBackground"
import HeroWelcome from "./heroWelcome"
import ImagesGrid from "./imagesGrid"
import ImagesGallery from "./imagesGallery"
import NumericGrid from "./numericGrid"
import CarouselWithParagraph from "./carouselWithParagraph"
import Carousel from "./carousel"
import Contact from "./contact"
import ArticlesListWithSearch from "./articlesListWithSearch"
import ArticlesChessboard from "./articlesChessboard"
import ArticlesLatest from "./articlesLatest"
import PageHeadline from "./pageHeadline"
import FormattedText from "./formattedText"

const SectionsFactory = ({ section, page }) => {
  switch (section.__typename) {
    case "DatoCmsHeroBackgroundImage":
      return <HeroBackgroundImage content={section} />
    case "DatoCmsHeroCoverImage":
      return <HeroCoverImage content={section} />
    case "DatoCmsHeroSolidBackground":
      return <HeroSolidBackground content={section} />
    case "DatoCmsHeroWelcome":
      return <HeroWelcome content={section} />
    case "DatoCmsArticlesListWithSearch":
      return <ArticlesListWithSearch content={section} />
    case "DatoCmsImagesGrid":
      return <ImagesGrid content={section} />
    case "DatoCmsImagesGallery":
      return <ImagesGallery content={section} />
    case "DatoCmsNumericGrid":
      return <NumericGrid content={section} />
    case "DatoCmsArticlesChessboard":
      return <ArticlesChessboard content={section} />
    case "DatoCmsCarouselWithParagraph":
      return <CarouselWithParagraph content={section} />
    case "DatoCmsCarousel":
      return <Carousel content={section} />
    case "DatoCmsContact":
      return <Contact content={section} />
    case "DatoCmsArticlesLatest":
      return <ArticlesLatest content={section} />
    case "DatoCmsFormattedText":
      return <FormattedText content={section} />
    case "DatoCmsPageHeadline":
      return <PageHeadline content={section} page={page} />
    default:
      return null
  }
}

export default SectionsFactory
