import * as React from "react"
import parse from "html-react-parser"
import Button from "../../atoms/button"
import { HOMEPAGE_SLUG } from "../../../constants/constants"

const HeroSolidBackground = ({ content }) => {  
  const solidColor = content.backgroundColor?.hex
  const ctaButton = content.ctaButton[0]
  const url = ctaButton
    ? ctaButton.external
      ? ctaButton.destinationExternal
      : `${ctaButton.destination.slug === HOMEPAGE_SLUG ? "/" : `/${ctaButton.destination.slug}`}`
    : ""
  return (
    <section className={`${content.__typename} ${solidColor ? "" : 'bg-primary'}`} style={{ backgroundColor: solidColor }}>
      <div className="container mx-auto px-6 pt-8 pb-6 text-center text-white md:px-14 md:pt-24 md:pb-24">
        <h2 className="pb-4 font-philosopher md:pb-16 text-4xl">{content.headline || ""}</h2>
        <div className="lg:text-xl">{parse(content.paragraph)}</div>
        {ctaButton && (
          <Button
            className="mt-5 md:mt-10"
            url={url}
            text={ctaButton.labelText}
            color="white"
            external={ctaButton.external}
          />
        )}
      </div>
    </section>
  )
}

export default HeroSolidBackground
