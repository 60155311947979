import { graphql } from "gatsby"
import * as React from "react"
import Template from "../components/templates/template"
import Theme from "../components/theme"
import "../styles/homepage.scss"
import SectionsFactory from "../components/organisms/sections/sectionsFactory"
import { HOMEPAGE_SLUG } from "../constants/constants"
import { useState, useEffect } from "react"
import { renderMetaTags } from "react-datocms"

const Page = ({ data }) => {
  const page = data.datoCmsPage
  const sections = page.sections
  const bgColor = page.backgroundColor?.hex
  const isHomepage = page.slug === HOMEPAGE_SLUG
  const [isHeaderAffected, setIsHeaderAffected] = useState(false)

  return (
    <Template bgColor={bgColor} pageName={page.slug} isHeaderAffected={isHeaderAffected}>
      {sections.map((section, index) => {
        const isHeroWelcome = section.__typename === "DatoCmsHeroWelcome"
        const isAtFirstPlace = index === 0
        if (isAtFirstPlace && isHeroWelcome && isHomepage) {
          useEffect(() => {
            setIsHeaderAffected(true)
          }, [])
          section.affectsHeader = isHeaderAffected
        }
        return section.originalId && <SectionsFactory key={section.originalId} section={section} page={page} />
      })}
    </Template>
  )
}

export const query = graphql`
  query PageQuery($originalId: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    datoCmsPage(originalId: { eq: $originalId }, locale: { eq: $language }) {
      slug
      title
      originalId
      locale
      backgroundColor {
        hex
      }
      seoMetaTags {
        tags
      }
      sections {
        __typename
        ... on DatoCmsHeroSolidBackground {
          originalId
          headline
          paragraph
          backgroundColor {
            hex
          }
          ctaButton {
            labelText
            external
            destination {
              slug
              locale
            }
            destinationExternal
          }
        }
        ... on DatoCmsHeroBackgroundImage {
          originalId
          headline
          paragraph
          image {
            alt
            title
            gatsbyImageData(placeholder: BLURRED)
          }
          ctaButton {
            labelText
            external
            destination {
              slug
              locale
            }
            destinationExternal
          }
        }
        ... on DatoCmsHeroCoverImage {
          originalId
          headline
          paragraph
          image {
            alt
            title
            gatsbyImageData(placeholder: BLURRED)
          }
          ctaButton {
            labelText
            external
            destination {
              slug
              locale
            }
            destinationExternal
          }
        }
        ... on DatoCmsImagesGrid {
          originalId
          id
          headline
          images {
            alt
            gatsbyImageData(placeholder: BLURRED, width: 200, height: 200)
            title
            originalId
          }
        }
        ... on DatoCmsArticlesListWithSearch {
          originalId
          locale
          articlesPerPage
        }
        ... on DatoCmsNumericGrid {
          id
          originalId
          heading
          paragraphs {
            id
            paragraph
          }
        }

        ... on DatoCmsArticlesChessboard {
          originalId
          heading
          articles {
            excerpt
            id
            title
            slug
            coverImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }

        ... on DatoCmsCarouselWithParagraph {
          originalId
          sectionOverlappingEffect
          transitionType
          articles {
            excerpt
            title
            originalId
            slug
            coverImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }

        ... on DatoCmsCarousel {
          originalId
          articles {
            originalId
            title
            slug
            coverImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ... on DatoCmsHeroWelcome {
          id
          originalId
          sliderDelay
          slides {
            headline
            originalId
            image {
              url
              alt
            }
          }
        }
        ... on DatoCmsContact {
          id
          originalId
          paragraph
          headline
          form
          informations {
            id
            headline
            informations
          }
        }
        ... on DatoCmsArticlesLatest {
          id
          originalId
          paragraph
          headline
          articles {
            excerpt
            title
            originalId
            slug
            coverImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
            category {
              name
            }
          }
        }
        ... on DatoCmsImagesGallery {
          id
          originalId
          images {
            alt
            gatsbyImageData(placeholder: BLURRED, width: 1200, height: 1200)
            title
            originalId
          }
        }
        ... on DatoCmsFormattedText {
          id
          originalId
          content {
            value
            links {
              __typename
              ... on DatoCmsPage {
                id: originalId
                slug
                title
              }
              ... on DatoCmsPost {
                id: originalId
                slug
                title
              }
            }
            blocks {
              __typename
              ... on DatoCmsImagesGallery {
                id: originalId
                images {
                  alt
                  gatsbyImageData(placeholder: BLURRED, width: 1200, height: 1200)
                  title
                  originalId
                }
              }
            }
          }
        }
        ... on DatoCmsPageHeadline {
          id
          originalId
          titleAlignment
        }
      }
    }
  }
`

export default Page

export const Head = ({ data }) => {
  const remapedSeoTags = data.datoCmsPage.seoMetaTags.tags.map(tag => {
    return {
      tag: tag.tagName,
      attributes: tag.attributes,
      content: tag.content || null,
    }
  })
  return (
    <>
      {renderMetaTags(remapedSeoTags)}
      <Theme />
    </>
  )
}
